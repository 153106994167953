// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'
import sum from 'lodash/sum'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import about from '../seo/about.json'

import flattenGatsbyNode from '../methods/flatten-gatsby-node'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

const pageSchema = {
  pageTitle: 'Homepage',
  slug: '',
  abstract: about.text,
  breadcrumbs: [{ title: 'Homepage', slug: '' }],
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper
    className="homepage two-col"
    pageSchema={pageSchema}
    {...props}
  >
    <h1>Open Access Archive</h1>
    <p className="c">
      Welcome! The Auroville Open-Access Archive provides access to images (and
      in the future, articles and videos) selected from the collection of
      Auroville Archives.
    </p>
    <p className="c">
      The Auroville Archives have been built up by contributions from
      Aurovilians and well-wishers over the last three decades. Our photos
      collection includes documentation of the earliest days of Auroville,
      important events, daily life, visits, developments, work, arts and more.
    </p>
    <p className="c">
      The Open-Access Archive offers glimpses into the Aurovilles of the past.
      Images can be browsed by Category, Keywords, Year, Decade, or Author. The
      search feature allows for customized results.
    </p>
    <p className="c">
      The interface can be adjusted as per your preference: pictures displayed
      in a list or grid, and choice of color scheme.
    </p>
    <p className="c">
      Through this project we also invite your input to fill in some of the
      missing information about our images. If you recognize the people, place,
      or context of a photo, please share with us what you remember using the
      form on each image page.
    </p>
    <p className="c">
      For questions or feedback please write to us: avarchives@auroville.org.in
    </p>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
